.drop-area {
  width: 100%;
  height: 100%;
  // border: 2px dashed #CCD8FF;
  // border-radius: 5px;

  &:focus {
    outline: none !important;
  }
  * {
    cursor: pointer;
  }
}

.edit-image-container {
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30%;
  width: 100%;
  background-color: #262a2e;
  transition: opacity 0.2s linear;

  button {
    border: none;
  }
}