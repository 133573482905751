.sign-in-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .sign-in-image-wrapper {
    width: 200px;
    padding: 20px 20px 50px;
  }
  .sign-in-form-wrapper {
    box-shadow: var(--shadow-1);
    padding: 30px;
    border-radius: 10px;
    max-width: 90%;
  
    .sign-in-loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sign-in-button {
      min-width: 170px;
    }

    label {
        font-size: 12px;
        color: #a0a8b3;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: var(--font-secondary);
        margin-bottom: 14px;
        text-align: left;
        display: block;
    }
  
    input {
        background-color: #191b1e;
        border-radius: 6px;
        height: 55px;
        transition: var(--transition);
        border: 2px solid #191b1e;
        padding: 0 15px;
        font-size: 14px;
        font-family: var(--font-secondary);
        color: var(--color-lightn);
        box-shadow: var(--inner-shadow);
        letter-spacing: 1px;
  
        @media #{$small-mobile} {
            margin-bottom: 15px;
        }
  
        &:focus {
            background-color: #191b1e;
            // background: #62011e;
            color: var(--color-light);
            border-color: var(--color-primary);
            box-shadow: none;
        }
    }
  
    textarea {
        min-height: 235px;
        background-color: #191b1e;
        border-radius: 6px;
        padding: 0;
        transition: var(--transition);
        border: 2px solid #191b1e;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 18px;
        font-family: var(--font-secondary);
        color: var(--color-lightn);
        box-shadow: var(--inner-shadow);
        letter-spacing: 1px;
        resize: none;
  
        @media #{$sm-layout} {
            min-height: 150px;
        }
  
        &:focus {
            border-color: var(--color-primary);
            color: var(--color-light);
            background-color: #191b1e !important;
            box-shadow: none;
        }
    }
  
    .introduce {
        display: flex;
        justify-content: space-between;
  
        input {
            width: 100%;
        }
    }
  
    a {
        &.rn-btn {
            span {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                font-family: var(--font-secondary);
                transform: var(--transition);
                margin-left: 5px;
            }
  
            svg {
                transition: var(--transition);
            }
        }
  
        &:hover {
            svg {
                margin-left: 10px;
            }
        }
    }
  
    .email-area {
        margin-top: 30px;
  
        @media #{$small-mobile} {
            margin-top: 0;
        }
    }
  
    .subject {
        margin-top: 30px;
  
        @media #{$small-mobile} {
            margin-top: 0;
        }
    }
  
    .message {
        margin-top: 30px;
  
        @media #{$small-mobile} {
            margin-top: 0;
        }
    }
  
    .rn-btn {
        @media #{$small-mobile} {
            display: block;
            text-align: center;
        }
    }
  }
}
