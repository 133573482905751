.admins-page {
  .admin-item {
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    border-radius: 5px;
    .admin-email {
      font-size: 20px;
      font-weight: bold;
    }
    .admin-type {
      font-size: 16px;

      &.super-admin {
        color: var(--color-primary);
      }
    }
  }
  .create-admin {
    border: none;
    padding: 0;

    & > div {
      position: fixed;
      bottom: 50px;
      right: 30px;
      cursor: pointer;
      z-index: 999;
      width: 50px;
      height: 50px;
      line-height: 46px;
      border-radius: 50%;
      background-color: #212428;
      text-align: center;
      z-index: 999 !important;
      box-shadow: var(--shadow-1);
    }
  }
}