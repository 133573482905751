.dashboard-page {
  .items-container {
    width: 100%;

    .dashboard-item {
      box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100px;
      cursor: pointer;
      &:hover {
        .dashboard-item-title {
          color: #ffffff;
        }
      }
      @media (min-width: 768px) {
        width: 250px;
      }
      .dashboard-item-title {
        font-size: 24px;
        font-weight: 600;
      }
      .dashboard-item-text {
        font-size: 18px;
        font-weight: 500;
        width: 100%;
        text-align: right;
        color: var(--color-primary);
      }
    }
  }
  .visit-title {
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    cursor: pointer;
    &:hover {
      color: #ffffff;
    }
  }
  .charts-container {
    .visit-chart-container {
      height: 300px;
      flex-grow: 1;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    .visit-platform-chart-container {
      height: 300px;
      width: 500px;
    }
  }
}