.service-modal {
  .service-title {
    font-size: 26px;
    color: var(--white);
    text-decoration: underline;
  }
  .service-text {
    width: 100%;
    font-size: 18px;
    color: #d8d8d8;
    background: transparent;
    margin: unset;
    padding: 0;
    overflow: unset;
  }
}