.user-expertise {
  border: 1px dashed #ffffff1a;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  div.expertise-title {
    width: 100%;
    font-size: 14px;
    text-align: center;
  }

  .user-skill-icon {
    transition: all .2s linear;
    cursor: default;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;

    &:hover {
      transform: translateY(-5px);
    }

    &.icon-dark {
      background: var(--background-color-1);
      box-shadow: var(--shadow-1);
    }
    &.icon-light {
      background: var(--gradient-box-w);
      box-shadow: var(--shadow-white-3);
    }
  }
}