.feedback-modal {
  .feedback-quote {
    width: 40px;
  }
  .feedback-text {
    width: 100%;
    font-size: 20px;
    color: #d8d8d8;
    background: transparent;
    margin: unset;
    padding: 0;
    overflow: unset;
  }
  .feedback-client {
    font-size: 16px;
  }
}