.loading-overlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF25;

  transition: opacity 0.3s linear;

  &.visible {
    opacity: 1;
    z-index: 999;
  }
}