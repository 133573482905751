.users-page {
  .create-user {
    border: none;
    padding: 0;

    & > div {
      position: fixed;
      bottom: 50px;
      right: 30px;
      cursor: pointer;
      z-index: 999;
      width: 50px;
      height: 50px;
      line-height: 46px;
      border-radius: 50%;
      background-color: #212428;
      text-align: center;
      z-index: 999 !important;
      box-shadow: var(--shadow-1);
    }
  }
}