.white-version {
  .modal {
    background: #ecf0f350 !important;
  }
  .header-username {
    color: var(--color-body-white);
  }
  .header-slogan {
    color: var(--color-midgray);
  }
  .user-bio-container span.with-background {
    background-color: #d7d7d775 !important;
  }
  .custom-expertise {
    color: var(--color-body-white);
  }
  .social-share-style-1 .social-share li a:hover svg {
    color: var(--color-white) !important;
  }
  .rn-service {
    .inner .content .title {
      color: var(--color-heading-wv);
    }
    &:hover {
      .inner svg {
        color: var(--color-white);
      }
      .inner .content .title {
        color: var(--color-white);
      }
    }
  }
  .service-title {
    color: var(--color-heading-wv);
  }
  .service-text {
    color: var(--color-body-white);
  }
  .portfolio-category {
    color: var(--color-heading-wv);
  }
  .portfolio-desc {
    color: var(--color-body-white);
  }
  button.rn-btn {
    background: var(--gradient-box-w);
    box-shadow: var(--shadow-white-3);
    color: var(--color-primary);
    &:hover {
      color: var(--color-white);
    }
  }
  .tech-stack-container {
    &::before {
      background-color: #dce1e4;
    }
  
    .tech-stack-item {
      background: var(--gradient-box-w);
      box-shadow: var(--shadow-white-3);
  
      span {
        color: var(--color-body-white);
      }
    }
  }
  .resume-single-list:hover {
    .inner {
      .heading span {
        color: var(--color-white);
      }
    }
  }
  .certification-item {
    .certification-name, .certification-date, .certification-id {
      color: var(--color-heading-wv);
    }
    &:hover {
      .certification-name, .certification-org, .certification-date, .certification-id {
        color: var(--color-white)
      }
    }
  }
  .interest-body {
    .check {
      background: var(--gradient-box-w) !important;
      box-shadow: var(--shadow-white-3) !important;

      svg {
        color: var(--color-heading-wv) !important;
      }
      .interest-title {
        color: var(--color-heading-wv) !important;
      }
    }
  }
  .rn-client-area {
    .client-feedback span {
      color: var(--color-body-white);
    }
  }
  .feedback-text {
    color: var(--color-body-white);
  }
  .contact-about-area .description span.address span:last-of-type {
    color: var(--color-body-white)
  }
  .expertise-title {
    color: var(--color-body-white);
  }
  .user-expertise {
    border-color: #dce1e4;
  }

  .role-container {
    .role-item {
      background: var(--gradient-box-w);
      box-shadow: var(--shadow-white-3);
  
      span {
        color: var(--color-vimeo);
      }
    }
  }
  .contact-form-wrapper textarea:focus {
    background-color: #fdfefe !important;
    color: var(--color-body-white) !important;
  }
  .footer-availability {
    color: var(--color-body-white) !important;
  }
}