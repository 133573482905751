.links-container {
  width: 100%;
}

.gun-io {
  height: 22px;
}

.guru-com {
  height: 22px;
}

.admin-links-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .admin-links-item-container {
    padding: 10px;
    cursor: pointer;

    .admin-link-item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      box-shadow: var(--shadow-1);
      border-radius: 6px;
      text-align: center;
      &.checked {
        background-color: var(--color-info);
      }
    }
  }
}