.subsection-separator {
  &:before {
    content: ' ';
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    background-color: #121415;
    z-index: -1;
  }

  span {
    color: #FFFFFF;
    background-color: #212428;
  }
}