.user-resume-container {
  .accordion-item {
    padding-bottom: 3rem;

    .accordion-header {
      button {
        border: none;
        box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem 3rem;
        border-radius: 8px;
        color: var(--color-primary);

        &.collapsed {
          color: var(--color-light);
        }
      }
    }
  }
  .accordion-title {
    font-size: 24px;
  }
  .new-badge {
    background-color: #dc3545;
    font-size: 10px;
    color: var(--color-light);
    position: absolute;
    border-radius: 2px;
    right: .5rem;
    top: -0.75rem;
  }
  .skill-category-badge {
    background-color: #dc3545;
    font-size: 10px;
    color: var(--color-light);
    position: absolute;
    border-radius: 2px;
    right: .5rem;
    top: -0.75rem;
  }
  .resume-action-button {
    padding: 0;
    width: 90px;
    height: 35px;

    .resume-button-text {
      font-size: 16px;
    }
  }
  .resume-item-row {
    border: 1px dashed transparent;
    border-radius: 3px;
    &:hover {
      .drag-handler {
        opacity: 1;
      }
    }
    &.dragging {
      border-color: #ff014f;
      opacity: 0.5;
      .drag-handler {
        opacity: 1;
      }
      & ~ .resume-item-row {
        border-color: #212428;
      }
    }
    .resume-item {
      box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
      border-radius: 5px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }

      .resume-item-info {
        .resume-place {
          font-size: 16px;
        }
        .resume-duration {
          font-size: 12px;
        }
        .resume-degree {
          font-size: 16px;
          color: var(--color-primary);
        }
      }
    }
  }
}
.resume-form-modal {
  max-height: 65vh;
  overflow-y: auto;
}