/*---------------------------
    Interest Area Styles 
-----------------------------*/

.navigation-wrapper {
    margin-left: 10px;
    box-shadow: var(--shadow-1);

    @media #{$md-layout} {
        margin: 0;
    }

    @media #{$sm-layout} {
        margin: 0;
    }

    ul {
        border-radius: 10px;
        align-items: center;

        &.nav {
            background-color: var(--background-color-1);
            box-shadow: var(--shadow-1);
            justify-content: space-between;
            margin: 0;
            padding: 0;
            border: 0;

            @media #{$small-mobile} {
                display: block;
            }

            li {
                flex-basis: 33.33%;
                margin: 0;

                &.recommended {
                    position: relative;

                    &::after {
                        content: "";
                        right: 60px;
                        top: -23px;
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 15px solid #545961;
                        z-index: 1;

                        @media #{$small-mobile} {
                            display: none;
                        }
                    }

                    &::before {
                        content: "Recommended";
                        position: absolute;
                        top: -50px;
                        right: 33px;
                        background: #545961;
                        padding: 5px 13px;
                        font-size: 12px;
                        border-radius: 30px;
                        color: #fff;
                        z-index: 2;

                        @media #{$small-mobile} {
                            top: 5px;
                            right: 5px;
                            background: #54596152;
                            border-radius: 9px;
                        }
                    }
                }

                a {
                    text-align: center;
                    display: block;
                    padding: 30px 10px;
                    border-radius: 6px;
                    transition: var(--transition);

                    &:hover,
                    &:focus,
                    &.active {
                        box-shadow: var(--shadow-1);
                        color: var(--color-primary);
                    }
                }
            }
        }
    }

    .tab-content {
        padding: 0 50px 40px 50px;

        @media #{$lg-layout} {
            padding: 0 40px 40px 40px;
        }

        @media #{$md-layout} {
            padding: 0 40px 40px 40px;
        }

        @media #{$sm-layout} {
            padding: 0 40px 40px 40px;
        }

        @media #{$small-mobile} {
            padding: 15px 15px;
        }
    }
}

.rn-interest {
    .interest-header {
        display: flex;
        margin-top: 55px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        @media #{$lg-layout} {
            margin-top: 30px;
            margin-bottom: 30px;
        }

        @media #{$small-mobile} {
            display: block;
            margin-bottom: 25px;
            margin-top: 30px;
        }

        .header-left {
            @media #{$small-mobile} {
                margin-bottom: 25px;
            }

            h2 {
                &.title {
                    font-size: 27px;
                    color: var(--color-lightn);
                    margin-bottom: 9px;

                    @media #{$large-mobile} {
                        font-size: 22px;
                    }
                }
            }

            span {
                color: #7c838e;
                font-family: var(--font-secondary);
            }
        }

        .header-right {
            span {
                color: var(--color-primary);
                font-size: 30px;
                font-weight: 600;
                padding: 9px 25px;
                box-shadow: var(--shadow-1);
                border-radius: 6px;
                display: inline-block;
            }
        }
    }

    .interest-body {
        p {
            &.description {
                font-size: 18px;
                line-height: 30px;
                max-width: 84%;
                font-weight: 400;
                color: #7c838e;
                font-family: var(--font-primary);

                @media #{$md-layout} {
                    margin-bottom: 30px;
                }

                @media #{$sm-layout} {
                    margin-bottom: 30px;
                }

                @media #{$large-mobile} {
                    margin-bottom: 30px;
                    font-size: 16px;
                }
            }
        }

        .check-wrapper {
            width: 100%;
            justify-content: space-between;
            display: flex;

            .left-area {
                margin-bottom: 30px;
            }

            .check {
                width: 100%;
                margin-bottom: 15px;

                .interest-description {
                    width: 100%;
                    padding-left: 30px;
                    margin-top: -5px;
                    font-size: 16px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                svg {
                    margin-top: 2px;
                    color: var(--color-lightn);
                }

                p {
                    margin-left: 5px;
                    color: var(--color-lightn);
                    font-weight: 400;
                    font-size: 18px;
                    font-family: var(--font-secondary);

                    @media #{$lg-layout} {
                        font-size: 16px;
                    }

                    @media #{$md-layout} {
                        font-size: 16px;
                    }

                    @media #{$sm-layout} {
                        font-size: 14px;
                    }

                    @media #{$large-mobile} {
                        font-size: 13px;
                    }
                }
            }

            .interest-item {
              border: none;
              border-radius: 5px;
              box-shadow: 0px 0px 19px #1c1e22, -10px -10px 19px #262a2e;;
            }

            @media #{$small-mobile} {
                display: block;
            }
        }
    }

    .interest-footer {
        text-align: center;
        margin-top: 50px;
        @media #{$lg-layout} {
            margin-top: 30px;
        }
        @media #{$md-layout} {
            margin-top: 15px;
        }
        @media #{$sm-layout} {
            margin-top: 15px;
        }
        .rn-btn {
            display: flex;
            margin-bottom: 25px;
            letter-spacing: 1px;
            font-family: var(--font-secondary);
            padding: 20px 0;

            span {
                margin-right: 5px;
                font-size: 16px;
            }

            svg {
                transition: var(--transition);
            }

            &:hover {
                svg {
                    margin-left: 10px;
                }
            }
        }

        .time-line {
            justify-content: center;
            display: flex;
            @media #{$small-mobile} {
                display: block !important;
                padding-left: 5px;
            }

            .single-cmt {
                margin-right: 15px;
                display: flex;
                &:nth-child(2) {
                    @media #{$small-mobile} {
                        margin-top: 5px;
                    }
                }
                
                svg {
                    width: 14px;
                    margin-right: 7px;
                    color: #9ca5b1;
                    margin-top: 2px;
                }

                span {
                    font-size: 12px;
                    font-weight: 500;
                    color: #9ca5b1;
                    margin-top: 5px;
                    letter-spacing: 1px;
                    font-family: var(--font-secondary);

                    @media #{$small-mobile} {
                        font-size: 12px;
                    }
                }
            }
            
        }
    }
}

.rn-interest-area {
    .section-title {
        @media #{$md-layout} {
            margin-bottom: 50px;
        }

        @media #{$sm-layout} {
            margin-bottom: 50px;
        }

        @media #{$small-mobile} {
            margin-bottom: 10px;
        }

        h2 {
            &.title {
                margin-bottom: 0;
            }
        }
    }
}

.sticky-top {
    z-index: 10 !important;
}

.interest-style-2 {
    .title-area-wrapper {
        margin-right: -20px;

        @media #{$large-mobile} {
            margin-right: 0;
        }

        @media #{$smlg-device} {
            margin-right: 0;
        }

        .section-title {
            margin-bottom: 0;

            h2 {
                &.title {
                    margin-bottom: 40px;

                    @media #{$md-layout} {
                        margin-bottom: 5px;
                    }
                }
            }
        }

        p {
            font-size: 16px;
            line-height: 28px;
            color: var(--color-lightn);
            margin: 0;
            font-family: var(--font-secondary);
            padding-right: 49px;

            @media #{$lg-layout} {
                padding-right: 0;
            }

            @media #{$md-layout} {
                padding-right: 0;
            }

            @media #{$sm-layout} {
                padding-right: 0;
            }

            @media #{$large-mobile} {
                text-align: center;
                padding-right: 0;
            }

            @media #{$small-mobile} {
                text-align: center;
                padding-right: 0;
            }

            &.title-disc {
                margin-bottom: 25px;

                @media #{$md-layout} {
                    margin-bottom: 8px;
                }

                @media #{$sm-layout} {
                    margin-bottom: 8px;
                }

                @media #{$large-mobile} {
                    text-align: center;
                    padding-right: 0;
                }

                @media #{$small-mobile} {
                    text-align: center;
                    padding-right: 0;
                }
            }
        }
    }

    .interest-wrapper,
    .interest-wrapper-pro {
        margin-left: 20px;
        position: relative;
        z-index: 1;
        padding: 40px;
        box-shadow: var(--shadow-1);
        border-radius: 10px;

        @media #{$md-layout} {
            margin-left: 0;
            margin-right: 0;
            margin-top: 30px;
        }

        @media #{$sm-layout} {
            margin-left: 0;
            margin-right: 0;
            margin-top: 30px;
        }

        @media #{$smlg-device} {
            margin-left: 0;
        }

        &::before {
            content: '';
            position: absolute;
            transition: var(--transition);
            width: 100%;
            height: 100%;
            border-radius: 10px;
            top: 0;
            left: 0;
            background: linear-gradient(to right bottom, #212428, #16181c);
            opacity: 0;
            z-index: -1;
        }

        .ts-header {
            margin-bottom: 30px;

            h6 {
                font-weight: 600;
                font-size: 16px;
                color: var(--color-white);
                margin-bottom: 10px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }

            span {
                color: var(--color-lightn);
                font-size: 16px;
            }
        }

        .price {
            font-size: 36px;
            font-family: var(--font-primary);
            color: var(--color-lightest);
            margin-bottom: 23px;
        }

        .interest-body {
            margin-bottom: 40px;

            .feature {
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                svg {
                    margin-right: 10px;
                    position: relative;
                    background-color: var(--color-lightn);
                    border-radius: 50%;
                    background: none;
                    color: var(--color-lightn);
                    opacity: 0.8;
                    width: 22px;

                    &.off {
                        color: #666666;
                        position: relative;
                        background-color: var(--color-lightn);
                        border-radius: 50%;
                        background: none;
                    }
                }

                .name {
                    color: var(--color-lightn);
                    font-weight: 400;
                    font-size: 16px;

                    &.off {
                        color: #646669;
                    }
                }
            }
        }

        .interest-footer {
            a.rn-btn {
                box-shadow: var(--shadow-1);
                display: block;
                text-align: center;
                padding: 14px;

                &::before {
                    background: linear-gradient(to left top, #212428, #16181c);
                }
            }
        }
    }

    .interest-wrapper-pro {
        margin-left: 5px;

        &::before {
            opacity: 0;
        }

        .interest-footer {
            a.rn-btn {
                box-shadow: var(--shadow-1);
            }
        }
    }

    .interest-wrapper {
        margin-right: -15px;

        @media #{$md-layout} {
            margin-right: 0;
        }

        @media #{$smlg-device} {
            margin-right: 0;
        }

        @media #{$sm-layout} {
            margin-right: 0;
            margin-left: 0;
            margin-top: 30px;
        }
    }
}