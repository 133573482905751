/*----------------------
Rn Slick Dots Style  
-----------------------*/
.slick-initialized .slick-slide {
    margin-bottom: 0 !important;
}

.slick-dots li button:before {
    display: none;
}

.rn-slick-dot-style {
    .slick-dots {
        width: auto;
        top: auto;
        z-index: 3;
        position: absolute;
        left: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        bottom: -40px;
        @media #{$lg-layout} {
            bottom: -4px;
        }
        li {
            width: auto;
            height: auto;
            padding: 10px;
            margin: 0;
            button {
                font-size: 0;
                line-height: 0;
                width: 11px;
                height: 11px;
                padding: 0;
                cursor: pointer;
                border: 0;
                background: var(--background-color-1);
                box-shadow: var(--inner-shadow);
                border-radius: 50%;
                transition: var(--transition);
                &::before {
                    display: none;
                }
                @media #{$md-layout} {
                    bottom: 3px;
                    position: relative;
                }
                @media #{$sm-layout} {
                    bottom: 3px;
                    position: relative;
                }
            }
            &.slick-active {
                button {
                    background: var(--color-primary);
                }
            }
        }
    }

    &.dot-position-left {
        .slick-dots {
            left: 0;
            bottom: 2%;
            transform: none;
        }
    }
}

.slick-arrow-style-one {
    .slick-arrow {
        position: absolute;
        bottom: 0;
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-align: center;
        opacity: 1;
        width: 60px;
        height: 60px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        box-shadow: var(--shadow-1);
        background-color: #212428;
        transition: 0.3s;
        left: -18px;
        border: 0;
        z-index: 2;

        @media #{$large-mobile} {
            width: 43px;
            height: 43px;
            top: 30%;
            left: 74%;
            border-radius: 23%;
        }

        svg {
            position: absolute;
            z-index: 100;
            color: #fff;
            width: 18px;
            height: 18px;
        }
        &:hover {
            background-color: #212428;
            svg {
                color: var(--color-primary);
            }
        }
    }
    .slick-next {
        &.slick-arrow {
            left: auto;
            right: -18px;

            @media #{$large-mobile} {
                left: 84%;
                top: 30%;
            }
        }
    }
    .slick-arrow {
        &:before {
            display: none;
        }
    }

    &.arrow-top-align {
        position: relative;
        .slick-arrow {
            width: 20px;
            height: 20px;
            top: -28%;
            padding: 23px;
            border-radius: 13%;
            background: #212428;
            box-shadow: var(--shadow-1);
            opacity: 1;
            z-index: 1;
            transition: 0.3s;
            &:hover {
                color: var(--color-primary);
            }
        }
        .slick-next {
            right: 0;
        }
        .slick-prev {
            left: 79%;
        }
    }
}

.testimonial-activation {
    position: relative;
    margin: 0 80px;
    @media #{$lg-layout} {
        margin: 0px;
    }
    @media #{$md-layout} {
        margin: 0px;
    }
    @media #{$sm-layout} {
        margin: 0px;
    }
    button {
        &.slide-arrow {
            font-size: 25px;
            position: absolute;
            right: 25px;
            width: 60px;
            height: 60px;
            line-height: 60px;
            background: var(--background-color-1);
            box-shadow: var(--shadow-1);
            border: 0 none;
            top: 80px;
            z-index: 3;
            border-radius: 6px;
            color: #fff;

            &:focus {
                color: var(--color-primary);
            }
            &:before {
                display: none;
            }
            &.slick-prev {
                right: 105px;
                margin-left: auto;

                @media #{$large-mobile} {
                    right: 80px;
                }
            }

            &::after {
                content: "";
                position: absolute;
                transition: var(--transition);
                width: 100%;
                height: 100%;
                border-radius: 6px;
                top: 0;
                left: 0;
                background: linear-gradient(to right bottom, #212428, #16181c);
                opacity: 0;
                z-index: -1;
            }

            @media #{$md-layout} {
                top: auto;
                bottom: -70px;
                display: none !important;
            }

            @media #{$sm-layout} {
                top: auto;
                bottom: -70px;
                display: none !important;
            }

            @media #{$large-mobile} {
                top: auto;
                bottom: -70px;
                right: 0;
            }

            &:hover {
                &::after {
                    opacity: 1;
                }

                color: var(--color-primary);
            }
        }
    }
    .slick-dots {
        width: auto;
        top: auto;
        z-index: 3;
        position: absolute;
        left: 50%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
        bottom: -40px;

        @media #{$lg-layout} {
            bottom: -4px;
        }

        li {
            width: auto;
            height: auto;
            padding: 10px;
            margin: 0;

            button {
                font-size: 0;
                line-height: 0;
                width: 11px;
                height: 11px;
                padding: 0;
                cursor: pointer;
                border: 0;
                background: var(--background-color-1);
                box-shadow: var(--inner-shadow);
                border-radius: 50%;
                transition: var(--transition);

                &::before {
                    display: none;
                }

                @media #{$md-layout} {
                    bottom: 3px;
                    position: relative;
                }

                @media #{$sm-layout} {
                    bottom: 3px;
                    position: relative;
                }
            }

            &.slick-active {
                button {
                    background: var(--color-primary);
                }
            }
        }
    }
}
