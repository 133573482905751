.contacts-page {
  .contact-item {
    cursor: pointer;
    box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
    border-radius: 5px;
    transition: all .2s linear;
    &:hover {
      background-color: var(--color-tertiary);
    }
    .contact-ref {
      font-size: 16px;
      color: var(--color-success);
    }
    .new-badge {
      background-color: var(--color-info);
      font-size: 10px;
      color: var(--color-white);
      position: absolute;
      border-radius: 2px;
      right: .5rem;
      top: -0.75rem;
    }
    .contact-info {
      .info-label {
        font-size: 18px;
      }
      .contact-to {
        font-size: 18px;
      }
      .contact-from {
        font-size: 18px;
        color: var(--color-vimeo);
      }
    }
    .contact-time {
      font-size: 10px;
      color: var(--color-body);
    }
    .contact-message {
      border-top: 1px dashed var(--color-tertiary);
      font-size: 14px;
      color: var(--color-midgray);
    }
  }
}