.tech-stack-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 15px -5px 0;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    top: -7px;
    background-color: #1a1d23;
    z-index: -1;
  }

  .tech-stack-item {
    padding: 2px 7px;
    border-radius: 2px;
    background: var(--background-color-1);
    box-shadow: var(--inner-shadow);
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 13px;
      color: #FFFFFF;
    }
  }
}