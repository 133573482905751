.Toastify__close-button {
  width: unset !important;
}

.flex-1 {
  flex: 1;  
}

.mh-100vh {
  min-height: 100vh;
}

.mw-100vw {
  min-width: 100vw;
}

.back-button {
  padding: 0 15px 2rem;

  button {
    padding: 15px !important;
  }
}

.loading-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.light {
    background-color: #ecf0f3;
  }
}

.logo-container {
  max-width: calc(100% - 20px);
}

.header-menu-logo {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
}

.header-menu-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rn-resume-area {
  .nav-tabs {
    .nav-item {
      margin-top: 0;
      margin-bottom: 0;
      flex: 1;
    }
  }
}

.nav-item {
  cursor: pointer;
}

.hero-avatar {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.header-avatar {
  img {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
}

.form-wrapper {
  &.form-dark {
    &.no-shadow {
      box-shadow: none !important;
    }
    box-shadow: var(--shadow-1);
    padding: 30px;
    border-radius: 10px;
    margin-left: 20px;
    height: 100%;

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #9197a0;
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9197a0;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #9197a0;
    }

    @media #{$md-layout} {
        margin: 0;
        margin-top: 0;
    }

    @media #{$sm-layout} {
        margin: 0;
        margin-top: 40px;
    }

    @media #{$small-mobile} {
        padding: 20px;
    }

    .form-group {
      label {
        user-select: none;
      }

      .form-error {
        width: 100%;
        font-size: 14px;
        text-align: left;
      }

      .required {
        color: #FFFFFF;
        font-size: 12px;
        margin-left: 5px;
      }
    }

    label {
        font-size: 12px;
        color: #a0a8b3;
        font-weight: 500;
        letter-spacing: 1px;
        // text-transform: uppercase;
        font-family: var(--font-secondary);
        margin-bottom: 14px;
        text-align: left;
        display: block;
    }

    input, select {
        background-color: #191b1e;
        border-radius: 6px;
        height: 55px;
        transition: var(--transition);
        border: 2px solid #191b1e;
        padding: 0 15px;
        font-size: 14px;
        font-family: var(--font-secondary);
        color: var(--color-lightn);
        box-shadow: var(--inner-shadow);
        letter-spacing: 1px;

        @media #{$small-mobile} {
            margin-bottom: 15px;
        }

        &:focus {
            background-color: #191b1e;
            // background: #62011e;
            color: var(--color-light);
            border-color: var(--color-primary);
            box-shadow: none;
        }
    }

    textarea {
        min-height: 75px;
        background-color: #191b1e;
        border-radius: 6px;
        padding: 0;
        transition: var(--transition);
        border: 2px solid #191b1e;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 18px;
        font-family: var(--font-secondary);
        color: var(--color-lightn);
        box-shadow: var(--inner-shadow);
        letter-spacing: 1px;
        resize: none;

        @media #{$sm-layout} {
            min-height: 150px;
        }

        &:focus {
            border-color: var(--color-primary);
            color: var(--color-light);
            background-color: #191b1e !important;
            box-shadow: none;
        }
    }

    .introduce {
        display: flex;
        justify-content: space-between;

        input {
            width: 100%;
        }
    }

    a {
        &.rn-btn {
            span {
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 1px;
                font-family: var(--font-secondary);
                transform: var(--transition);
                margin-left: 5px;
            }

            svg {
                transition: var(--transition);
            }
        }

        &:hover {
            svg {
                margin-left: 10px;
            }
        }
    }

    .email-area {
        margin-top: 30px;

        @media #{$small-mobile} {
            margin-top: 0;
        }
    }

    .subject {
        margin-top: 30px;

        @media #{$small-mobile} {
            margin-top: 0;
        }
    }

    .message {
        margin-top: 30px;

        @media #{$small-mobile} {
            margin-top: 0;
        }
    }

    .rn-btn {
        @media #{$small-mobile} {
            display: block;
            text-align: center;
        }
    }
  }
}

.custom-modal {
  &.modal-small {
    width: 40%;
    @media #{$sm-layout} {
      width: 100%
    }
    @media #{$md-layout} {
      width: 75%;
    }
    @media #{$lg-layout} {
      width: 60%;
    }
    @media #{$laptop-device} {
      width: 50%;
    }
    @media #{$extra-device} {
      width: 40%;
    }
  }
}

button.rn-btn {
  &:disabled {
    cursor: not-allowed;
    span {
      color: #88919c !important;
    }
  }
}

.admin-section-title {
  h3 {
    font-size: 28px;
  }
}

.modal[role=dialog] {
  background: rgba(0,0,0,.5);
  backdrop-filter: blur(7px);
}

.form-check, .form-group {
  position: relative;
  input[type=checkbox] {
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;

    & ~ label {
      width: 16px;
      height: 16px;
      padding-left: 25px;
  
      &::before {
        width: 16px;
        height: 16px;
      }
      &::after {
        width: 16px;
        height: 16px;
        top: 2px;
        left: 0;
        transform: unset;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      }
    }
  }
}

.certification-item {
  * {
    cursor: pointer;
  }
  padding: 20px 25px;

  .cert-icon-container {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    background-color: var(--color-lightn);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
  }
  .certification-name {
    color: #FFFFFF;
    font-weight: bold;
  }

  .certification-date {
    color: #FFFFFF;
    font-size: 14px;
  }

  .certification-id {
    color: #FFFFFF;
    font-size: 14px;
  }
}

.portfolio-user-image {
  max-height: 250px;
}

.client-feedback {
  .client-name {
    color: #7c838e;
    font-size: 13px;
    width: 100%;
    text-align: right;
  }

  .read-more {
    color: var(--color-subtitle);
    cursor: default;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
}

.rn-portfolio {
  padding: 15px !important;
}

.client-card .main-content {
  flex-basis: 50%;
}

.contact-about-area {
  font-size: 14px;
}

.contact-about-area .title-area .title {
  font-size: 24px !important;
  line-height: unset !important;
}