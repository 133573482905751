.user-csv-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 99;
  cursor: pointer;
  
  button {
    border: 0;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    min-width: 100%;
    padding: 10px 0 8px;
    position: relative;
    transition: all 0.2s linear;
    width: 100%;
    z-index: 2;
    padding-left: 5px;
    padding-right: 5px;
  
    &:not(:disabled):hover {
      color: var(--color-primary);
    }
  }

  &.dark {
    button {
      background: linear-gradient(145deg,#1e2024,#23272b);
      box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
      color: #FFFFFF;
    }
  }
  &.light {
    button {
      background: var(--gradient-box-w);
      box-shadow: var(--shadow-white-3);
      color: var(--color-primary);
    }
  }
}