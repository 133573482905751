.role-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  gap: 7px;

  .role-item {
    padding: 2px 10px 1px;
    background: var(--background-color-1);
    box-shadow: var(--inner-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    span {
      font-size: 12px;
      font-weight: bold;
      color: var(--color-vimeo);
    }
  }
}