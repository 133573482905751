/*---------------------------
    Service Area Styles 
-----------------------------*/

.rn-service {
    text-align: left;
    padding: 35px 40px;
    transition: 0.5s all ease-in-out;
    position: relative;
    border-radius: 10px;
    background: var(--background-color-1);
    box-shadow: var(--shadow-1);
    position: relative;
    z-index: 1;
    height: 100%;

    @media #{$md-layout} {
        padding: 30px;
    }

    @media #{$sm-layout} {
        padding: 30px;
    }

    @media #{$small-mobile} {
        padding: 20px;
    }

    a {
        &.over-link {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }
    }

    .inner {
        .icon {
            margin-bottom: 29px;

            @media #{$sm-layout} {
                margin-bottom: 10px;
            }

            svg {
                width: 50px;
                height: auto;
                color: var(--color-primary);
                stroke-width: 1 !important;
            }
        }

        .content {
            & > svg {
              width: 55px;
              height: auto;
              stroke-width: 1px;
              color: var(--color-primary);
              margin-bottom: 25px;
            }
            .title {
                font-family: var(--color-primary);
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 20px;
                color: var(--color-lightn);
                @media #{$sm-layout} {
                    margin-bottom: 15px;
                }
                a {
                    transition: var(--transition);
                }
            }

            p {
                &.description {
                    font-size: 16px;
                    line-height: 28px;
                    color: var(--color-lightn);
                    margin-bottom: 22px;
                    transition: var(--transition);

                    br {
                      display: none;
                    }
                }
            }

            .read-more-button {
                height: 0;
                visibility: hidden;
                opacity: 0;
                transition: var(--transition);

                &::after {
                    content: "";
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    position: absolute;
                    pointer-events: auto;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0);
                    width: 100%;
                    height: 100%;
                }

                i,
                svg {
                    color: var(--color-primary);
                    font-size: 32px;
                }
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        transition: var(--transition);
        width: 100%;
        height: 100%;
        border-radius: 10px;
        top: 0;
        left: 0;
        background: linear-gradient(to right bottom, #212428, #16181c);
        opacity: 0;
        z-index: -1;
    }

    &:hover {
        .inner {
            .content {
                .title {
                    color: var(--color-white);
                }

                .description {
                    color: var(--color-white);
                }

                .read-more-button {
                    height: auto;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        &::before {
            opacity: 1;
        }
    }
}

.rn-service .inner > * {
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transform: translateY(20px);
}

.rn-service:hover .inner > * {
    transform: translateY(0px);
}
