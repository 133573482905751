.user-slides {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;

  &.hasMultipleImages {
    position: relative;
  }

  .slick-slider {
    height: 100%;
  
    div {
      height: 100%;
    }
    .user-background {
      min-height: 100%;
      min-width: 100%;
      // object-fit: contain;
    }
  }
}
