.date-range-container {
  border-radius: 6px;
  overflow: hidden;
  // .rdrDateRangeWrapper {
  //   background: linear-gradient(145deg, #1e2024, #23272b);
  //   .rdrDateDisplayWrapper {
  //     background-color: linear-gradient(145deg, #1e2024, #23272b) !important;
  //   }
  // }

  @media screen and (max-width: 991px) {
    width: 100%; 
  }
  .dare-range {
    text-align: center;
    width: 100%;
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
    background: linear-gradient(145deg, #1e2024, #23272b);
    box-shadow: var(--shadow-1) !important;
    transition: var(--transition);
    position: relative;
    z-index: 2;
    font-size: 14px;
    font-weight: 500;
    border: 0 none;
    outline: none;
    display: inline-block;
    padding: 15px 25px;
    list-style: none;
    text-decoration: none;
    color: var(--color-primary);
  }
}

.date-ranger-popover {
  max-width: unset !important;
}